@import 'vars';
@import 'mixins';

.title, .subtitle {
  display: block;
  color: $text-color-inverse;
}

.title {
  font-size: 70px;
  margin: 20px 0 0 0;
  font-family: $font-family-sc;
  font-weight: $font-weight-regular;
}

.subtitle {
  font-size: 30px;
  margin-bottom: 100px;
  font-family: $font-family;
  font-weight: $font-weight-regular;
}

.link-lockup {
  display: flex;
  margin: 0 auto 100px auto;
  flex-direction: column;
  outline: thick solid $bg-color-light;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    > span {
      margin-left: 20px;
      color: $text-color-inverse;
      font-style: italic;
      font-weight: $font-weight-regular;
      font-size: 35px;
    }
  }
}

@include mobile-media-query {
  .link-lockup {
    width: $ad-width-mobile;

    a span span {
      display: none;
    }
  }
}
