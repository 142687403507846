@import 'vars';

@mixin primary-bar() {
  border-top: $primary-bar-width solid $primary-color;
}

@mixin val-to-props($val, $props) {
  @each $prop in $props {
    #{$prop}: #{$val};
  }
}

@mixin mobile-media-query() {
  @media (max-width: $ad-width-desktop + ($site-min-padding * 2)) {
    @content;
  }
}
