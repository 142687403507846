@import 'vars';
@import 'mixins';

.header {
  position: fixed;
  z-index: 100;
  @include val-to-props(0, top left right);

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $header-height;
    background-color: $secondary-color;

    a {
      display: flex;
      color: $text-color-inverse;
      text-decoration: none;
      align-items: center;

      h1 {
        display: inline-block;
        margin-left: .5rem;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: lighter;
        font-family: $font-family-sc;
        font-size: 45px;
      }
    }
  }
}
