@import 'vars';
@import 'mixins';

.responsivead {
  width: $ad-width-desktop;
  height: $ad-height-desktop;
  overflow: hidden;
}

.ad {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: $ad-width-desktop;
  height: $ad-height-desktop;
  background-color: $bg-color-light;
  border: 1px solid $secondary-color;

  h5 {
    font-family: $font-family;
    font-size: 30px;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
  }

  p {
    margin: 0;
    width: 220px;
    line-height: 1.3;
    text-align: right;
  }
}

@include mobile-media-query {
  .responsivead {
    width: $ad-width-mobile;
    height: $ad-height-mobile;
  }

  .ad {
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    width: $ad-width-mobile;
    height: $ad-height-mobile;
    text-align: center;

    p {
      text-align: center;
    }
  }
}
