@import 'vars';
@import 'mixins';

.footer {
  text-align: center;
  color: $text-color-inverse;
  background-color: $secondary-color;
  height: $footer-height;
  @include primary-bar();

  .links {
    margin-bottom: 40px;

    a {
      text-decoration: none;
      color: $text-color-inverse;
      font-size: 25px;
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .copy {
    font-size: 14px;
    color: #B9BBB8;
  }

  .kanji {
    margin-top: 50px;
    margin-bottom: 30px;
  }
}