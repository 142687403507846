@import 'vars';

.button {
  color: $text-color-inverse;
  background-color: $primary-color-bright;
  font-size: 20px;
  border-radius: 8px;
  padding: 14px 20px;
  cursor: pointer;
  line-height: .8;
  border: 0;

  &:focus {
    outline: none;
  }

  &[class*="full"] {
    width: 100%;
  }

  &[disabled] {
    background-color: $text-color;
    cursor: default;
  }
}
