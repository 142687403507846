@import 'layout';
@import 'vars';
@import 'mixins';

body, html {
  font-size: $font-size-default;
  font-family: $font-family;
  background-color: $bg-color;
  color: $text-color;

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-family;
    line-height: 1.1;
  }

  p {
    line-height: 1.5;
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
    color: $primary-color-bright;
  }

  &.splash {
    background-color: $secondary-color;

    main section {
      margin-top: 0;
      min-height: calc(100vh - #{$footer-height});
    }
  }

  main {
    min-width: $ad-width-mobile + ($site-min-padding * 2);

    section {
      padding: 0 $site-min-padding;
      margin-top: $header-height;
      min-height: calc(100vh - #{$footer-height} - #{$header-height});
      @include primary-bar();

      .container {
        margin: 0 auto;
        max-width: $ad-width-desktop;
        padding-top: 52px;
        padding-bottom: $site-section-bottom-padding;

        &.center {
          text-align: center;
        }

        &.right {
          text-align: right;
        }

        hr.line {
          @include val-to-props(0, border height margin);
          border-top: 6px solid $primary-color;

          + button {
            display: block;
            margin: 0 auto;
            margin-top: $site-inner-section-padding;
          }
        }

        .content {
          margin-top: $site-inner-section-padding;

          p + h2 {
            margin-top: $site-inner-section-padding;
          }
        }
      }
    }
  }
}
