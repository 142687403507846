@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:400,700|Alegreya+Sans:400,400i,700,700i');

$font-family: 'Alegreya Sans', sans-serif;
$font-size-default: 18px;
$font-family-sc: 'Alegreya Sans SC', sans-serif;
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-heading-size: 45px;
$font-subheading-size: 25px;

$bg-color: #CECFCD;
$bg-color-light: #E4E4E4;
$primary-color: #6F130E;
$primary-color-bright: #8C1610;
$secondary-color: #1F0504;
$text-color: $secondary-color;
$text-color-inverse: $bg-color;

$header-height: 113px;
$footer-height: 287px;

$primary-bar-width: 30px;

$ad-width-desktop: 728px;
$ad-height-desktop: 90px;
$ad-width-mobile: 300px;
$ad-height-mobile: 250px;

$site-min-padding: 15px;
$site-section-bottom-padding: 70px;
$site-inner-section-padding: 60px;
