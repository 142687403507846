@import 'vars';

.titles {
  text-align: center;
  margin-bottom: 25px;

  h2, h3 {
    margin-bottom: 0;
  }

  h2 {
    font-family: $font-family-sc;
    font-weight: $font-weight-bold;
    font-size: $font-heading-size;
  }

  h3 {
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: $font-subheading-size;
  }
}
