@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:400,700|Alegreya+Sans:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:400,700|Alegreya+Sans:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:400,700|Alegreya+Sans:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:400,700|Alegreya+Sans:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:400,700|Alegreya+Sans:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:400,700|Alegreya+Sans:400,400i,700,700i);
@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:400,700|Alegreya+Sans:400,400i,700,700i);
.style_responsivead__2DHBt {
  width: 728px;
  height: 90px;
  overflow: hidden; }

.style_ad__T42pd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 728px;
  height: 90px;
  background-color: #E4E4E4;
  border: 1px solid #1F0504; }
  .style_ad__T42pd h5 {
    font-family: "Alegreya Sans", sans-serif;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0; }
  .style_ad__T42pd p {
    margin: 0;
    width: 220px;
    line-height: 1.3;
    text-align: right; }

@media (max-width: 758px) {
  .style_responsivead__2DHBt {
    width: 300px;
    height: 250px; }
  .style_ad__T42pd {
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    height: 250px;
    text-align: center; }
    .style_ad__T42pd p {
      text-align: center; } }

.style_footer__t_XqW {
  text-align: center;
  color: #CECFCD;
  background-color: #1F0504;
  height: 287px;
  border-top: 30px solid #6F130E; }
  .style_footer__t_XqW .style_links__3wekK {
    margin-bottom: 40px; }
    .style_footer__t_XqW .style_links__3wekK a {
      text-decoration: none;
      color: #CECFCD;
      font-size: 25px;
      margin-right: 40px; }
      .style_footer__t_XqW .style_links__3wekK a:last-child {
        margin-right: 0; }
  .style_footer__t_XqW .style_copy__eAUUx {
    font-size: 14px;
    color: #B9BBB8; }
  .style_footer__t_XqW .style_kanji__3zWjD {
    margin-top: 50px;
    margin-bottom: 30px; }

.style_title__XvVpj, .style_subtitle__2yyUN {
  display: block;
  color: #CECFCD; }

.style_title__XvVpj {
  font-size: 70px;
  margin: 20px 0 0 0;
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 400; }

.style_subtitle__2yyUN {
  font-size: 30px;
  margin-bottom: 100px;
  font-family: "Alegreya Sans", sans-serif;
  font-weight: 400; }

.style_link-lockup__OiQ9a {
  display: flex;
  margin: 0 auto 100px auto;
  flex-direction: column;
  outline: thick solid #E4E4E4; }
  .style_link-lockup__OiQ9a a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0; }
    .style_link-lockup__OiQ9a a > span {
      margin-left: 20px;
      color: #CECFCD;
      font-style: italic;
      font-weight: 400;
      font-size: 35px; }

@media (max-width: 758px) {
  .style_link-lockup__OiQ9a {
    width: 300px; }
    .style_link-lockup__OiQ9a a span span {
      display: none; } }

.style_header__3lhHv {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0; }
  .style_header__3lhHv .style_container__2qEf7 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 113px;
    background-color: #1F0504; }
    .style_header__3lhHv .style_container__2qEf7 a {
      display: flex;
      color: #CECFCD;
      text-decoration: none;
      align-items: center; }
      .style_header__3lhHv .style_container__2qEf7 a h1 {
        display: inline-block;
        margin-left: .5rem;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: lighter;
        font-family: "Alegreya Sans SC", sans-serif;
        font-size: 45px; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit;
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0; }

optgroup {
  font-weight: 700; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: button; }

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

html {
  font: 112.5%/1.45em georgia, serif;
  box-sizing: border-box;
  overflow-y: scroll; }

* {
  box-sizing: inherit; }

*:before {
  box-sizing: inherit; }

*:after {
  box-sizing: inherit; }

body {
  color: rgba(0, 0, 0, 0.8);
  font-family: georgia, serif;
  font-weight: normal;
  word-wrap: break-word;
  -webkit-font-kerning: normal;
          font-kerning: normal;
  -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-feature-settings: 'kern', 'liga', 'clig', 'calt', "kern"; }

img {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

h1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 2.25rem;
  line-height: 1.1; }

h2 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.62671rem;
  line-height: 1.1; }

h3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.38316rem;
  line-height: 1.1; }

h4 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  line-height: 1.1; }

h5 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.85028rem;
  line-height: 1.1; }

h6 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.78405rem;
  line-height: 1.1; }

hgroup {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

ul {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  list-style-position: outside;
  list-style-image: none; }

ol {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  list-style-position: outside;
  list-style-image: none; }

dl {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

dd {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

figure {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

pre {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 1.45rem;
  font-size: 0.85rem;
  line-height: 1.42;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  overflow: auto;
  word-wrap: normal;
  padding: 1.45rem; }

table {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  font-size: 1rem;
  line-height: 1.45rem;
  border-collapse: collapse;
  width: 100%; }

fieldset {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

blockquote {
  margin-left: 1.45rem;
  margin-right: 1.45rem;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

form {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

noscript {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

iframe {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

hr {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: calc(1.45rem - 1px);
  background: rgba(0, 0, 0, 0.2);
  border: none;
  height: 1px; }

address {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem; }

b {
  font-weight: bold; }

strong {
  font-weight: bold; }

dt {
  font-weight: bold; }

th {
  font-weight: bold; }

li {
  margin-bottom: calc(1.45rem / 2); }

ol li {
  padding-left: 0; }

ul li {
  padding-left: 0; }

li > ol {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2); }

li > ul {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2); }

blockquote *:last-child {
  margin-bottom: 0; }

li *:last-child {
  margin-bottom: 0; }

p *:last-child {
  margin-bottom: 0; }

li > p {
  margin-bottom: calc(1.45rem / 2); }

code {
  font-size: 0.85rem;
  line-height: 1.45rem; }

kbd {
  font-size: 0.85rem;
  line-height: 1.45rem; }

samp {
  font-size: 0.85rem;
  line-height: 1.45rem; }

abbr {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
  cursor: help; }

acronym {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
  cursor: help; }

abbr[title] {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
  cursor: help;
  text-decoration: none; }

thead {
  text-align: left; }

td,
th {
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-feature-settings: 'tnum';
  -moz-font-feature-settings: 'tnum';
  -ms-font-feature-settings: 'tnum';
  -webkit-font-feature-settings: 'tnum';
  padding-left: 0.96667rem;
  padding-right: 0.96667rem;
  padding-top: 0.725rem;
  padding-bottom: calc(0.725rem - 1px); }

th:first-child,
td:first-child {
  padding-left: 0; }

th:last-child,
td:last-child {
  padding-right: 0; }

tt,
code {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  font-family: 'SFMono-Regular', Consolas, 'Roboto Mono', 'Droid Sans Mono', 'Liberation Mono', Menlo, Courier, monospace;
  padding: 0;
  padding-top: 0.2em;
  padding-bottom: 0.2em; }

pre code {
  background: none;
  line-height: 1.42; }

code:before,
code:after,
tt:before,
tt:after {
  letter-spacing: -0.2em;
  content: ' '; }

pre code:before,
pre code:after,
pre tt:before,
pre tt:after {
  content: ''; }

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%; } }

body, html {
  font-size: 18px;
  font-family: "Alegreya Sans", sans-serif;
  background-color: #CECFCD;
  color: #1F0504; }
  body h1, body h2, body h3, body h4, body h5, body h6, html h1, html h2, html h3, html h4, html h5, html h6 {
    font-family: "Alegreya Sans", sans-serif;
    line-height: 1.1; }
  body p, html p {
    line-height: 1.5;
    margin-bottom: 20px; }
  body a, html a {
    text-decoration: none;
    color: #8C1610; }
  body.splash, html.splash {
    background-color: #1F0504; }
    body.splash main section, html.splash main section {
      margin-top: 0;
      min-height: calc(100vh - 287px); }
  body main, html main {
    min-width: 330px; }
    body main section, html main section {
      padding: 0 15px;
      margin-top: 113px;
      min-height: calc(100vh - 287px - 113px);
      border-top: 30px solid #6F130E; }
      body main section .container, html main section .container {
        margin: 0 auto;
        max-width: 728px;
        padding-top: 52px;
        padding-bottom: 70px; }
        body main section .container.center, html main section .container.center {
          text-align: center; }
        body main section .container.right, html main section .container.right {
          text-align: right; }
        body main section .container hr.line, html main section .container hr.line {
          border: 0;
          height: 0;
          margin: 0;
          border-top: 6px solid #6F130E; }
          body main section .container hr.line + button, html main section .container hr.line + button {
            display: block;
            margin: 0 auto;
            margin-top: 60px; }
        body main section .container .content, html main section .container .content {
          margin-top: 60px; }
          body main section .container .content p + h2, html main section .container .content p + h2 {
            margin-top: 60px; }

.style_titles__18d8b {
  text-align: center;
  margin-bottom: 25px; }
  .style_titles__18d8b h2, .style_titles__18d8b h3 {
    margin-bottom: 0; }
  .style_titles__18d8b h2 {
    font-family: "Alegreya Sans SC", sans-serif;
    font-weight: 700;
    font-size: 45px; }
  .style_titles__18d8b h3 {
    font-family: "Alegreya Sans", sans-serif;
    font-weight: 400;
    font-size: 25px; }

.style_button__1NpAh {
  color: #CECFCD;
  background-color: #8C1610;
  font-size: 20px;
  border-radius: 8px;
  padding: 14px 20px;
  cursor: pointer;
  line-height: .8;
  border: 0; }
  .style_button__1NpAh:focus {
    outline: none; }
  .style_button__1NpAh[class*="full"] {
    width: 100%; }
  .style_button__1NpAh[disabled] {
    background-color: #1F0504;
    cursor: default; }

